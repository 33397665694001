import FroalaEditor from 'froala-editor'
;(() => {
  FroalaEditor.RegisterCommand('catalog', {
    title: '目次生成',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      const { mokuji, insertToHTML } = initializeMokuji(this.el)
      const mokujiHead = document.createElement('div')

      mokujiHead.innerText = '目次'
      mokujiHead.classList.add('mokuji-head')
      mokuji.appendChild(mokujiHead)

      if (this.el.querySelectorAll('h2, h3').length > 0) {
        mokuji.appendChild(mokujiContentBuilder(this.el))
      } else {
        const ol = document.createElement('ol')

        ol.appendChild(document.createElement('li'))
        mokuji.appendChild(ol)
      }
      if (insertToHTML) {
        const nextLine = document.createElement('p')

        this.html.insert(mokuji.outerHTML + nextLine.outerHTML)
      }
      this.undo.saveStep()
    },
  })
})()

const mokujiContentBuilder = (froalaContent) => {
  const ol = document.createElement('ol')
  let li = document.createElement('li')
  let h3Ol = document.createElement('ol')

  froalaContent.querySelectorAll('h2, h3').forEach((el) => {
    if (el.tagName === 'H2') {
      if (h3Ol.children.length > 0) {
        li.appendChild(h3Ol)
        h3Ol = document.createElement('ol')
      }
      if (li.children.length > 0) {
        ol.appendChild(li)
        li = document.createElement('li')
      }
      el.id = `no${ol.children.length + 1}`
      el.classList.add('h2-ordered-list')
      li.appendChild(linkBuilder(el))
    } else if (el.tagName === 'H3') {
      el.id = `no${ol.children.length + 1}.${h3Ol.children.length + 1}`
      el.classList.add('h3-ordered-list')
      const h3Li = document.createElement('li')

      h3Li.appendChild(linkBuilder(el))
      h3Ol.appendChild(h3Li)
    }
  })
  if (h3Ol.children.length > 0) {
    li.appendChild(h3Ol)
  }
  if (li.children.length > 0) {
    ol.appendChild(li)
  }

  return ol
}

const linkBuilder = (froalaContent) => {
  const link = document.createElement('a')

  link.href = `#${froalaContent.id}`
  link.innerText = froalaContent.innerText.replace(/\n/g, ' ')

  return link
}

const initializeMokuji = (froalaContent) => {
  let mokujiElement = froalaContent.querySelector('.mokuji')
  let insertHTMLFlag = false

  if (!mokujiElement) {
    mokujiElement = document.createElement('div')
    mokujiElement.classList.add('mokuji')
    insertHTMLFlag = true
  } else {
    mokujiElement.innerHTML = ''
  }

  return { mokuji: mokujiElement, insertToHTML: insertHTMLFlag }
}
