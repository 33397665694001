import { Controller } from 'stimulus'
import { uppyInitWrapper } from '../../src/fileInputUpload'
import Cropper from 'cropperjs'

export default class extends Controller {
  static targets = ['image', 'fileInput', 'hiddenInput']

  connect() {
    this.aspectRatio = this.element.dataset.aspectRatio

    this.initFileUploader()
    this.styleButton()
  }

  buildCropBox(uploadURL) {
    const image = this.imageTarget
    const hiddenInput = this.hiddenInputTarget

    if (image.cropper) {
      image.cropper.replace(uploadURL)

      return
    }

    image.src = uploadURL

    new Cropper(image, {
      aspectRatio: parseFloat(this.aspectRatio),
      viewMode: 1,
      crop(event) {
        const inputValue = hiddenInput.value
        const fileData = JSON.parse(inputValue)

        fileData.metadata.crop = event.detail
        hiddenInput.value = JSON.stringify(fileData)
      },
    })
  }

  initFileUploader() {
    uppyInitWrapper({
      input: this.fileInputTarget,
      hiddenInput: this.hiddenInputTarget,
      uploadSuccessCallback: (uploadURL) => this.buildCropBox(uploadURL),
    })
  }

  styleButton() {
    const uploadButton = this.element.querySelector('.uppy-FileInput-btn')
    const wrapper = uploadButton.parentNode

    uploadButton.classList.add('btn', 'dpib')
    wrapper.classList.add('u-mt_large')
  }
}
