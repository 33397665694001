import FroalaEditor from 'froala-editor'
;(() => {
  FroalaEditor.RegisterCommand('highlight-block', {
    title: 'ハイライトブロック',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    callback: function () {
      const highlightedBlock = this.selection
        .element()
        .closest('.highlight-block')

      if (!highlightedBlock) {
        const element = this.selection.element()
        const highlightBlock = document.createElement('div')
        highlightBlock.classList.add('highlight-block')
        highlightBlock.innerHTML = element.outerHTML

        const paragraph = document.createElement('p')
        paragraph.appendChild(document.createElement('br'))

        if (element.closest('ul, ol')) {
          const topList = findTopList(element)
          highlightBlock.innerHTML = topList.outerHTML
          topList.remove()
        }

        if (highlightBlock.querySelectorAll(':not(br)').length === 0) {
          highlightBlock.innerHTML = paragraph.outerHTML
        }

        element.remove()
        this.html.insert(highlightBlock.outerHTML + paragraph.outerHTML)
      } else {
        highlightedBlock.outerHTML = highlightedBlock.innerHTML
      }
      this.undo.saveStep()
    },
  })
})()

const findTopList = (node) => {
  let currentNode = node

  while (currentNode.parentNode.closest('ul, ol')) {
    currentNode = currentNode.parentNode.closest('ul, ol')
  }

  return currentNode
}
