import AwsS3 from '@uppy/aws-s3'
import Core from '@uppy/core'
import FileInput from '@uppy/file-input'
import Japanese from '@uppy/locales/lib/ja_JP'

export function uppyInitWrapper({ input, hiddenInput, uploadSuccessCallback }) {
  const generatedHash = Math.random().toString(16).substr(2)
  const fileInput = input
  const inputParentNode = fileInput.parentNode
  const uppy = Core({
    locale: Japanese,
    autoProceed: true,
    restrictions: {
      maxFileSize: 5 * 1024 * 1024,
      allowedFileTypes: ['.jpeg', '.jpg', '.png', '.gif'],
    },
  })

  fileInput.remove()

  uppy
    .use(FileInput, {
      id: `FileInput-${generatedHash}`,
      target: inputParentNode,
      locale: {
        strings: {
          chooseFiles: 'ファイル',
        },
      },
    })
    .use(AwsS3, {
      id: `AwsS3-${generatedHash}`,
      limit: 1,
      getUploadParameters(file) {
        const url = `/admin/s3/params?filename=${file.name}&type=${file.type}`

        return fetch(url, {
          method: 'get',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
          },
        }).then((response) => response.json())
      },
    })

  uppy.on('restriction-failed', (_file, error) => {
    alert(error)
  })

  uppy.on('upload-success', (file) => {
    const uploadURL = file.xhrUpload.endpoint + '/' + file.meta.key
    const uploadedFileData = {
      id: file.meta.key.match(/cache\/(.+)/)[1],
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type, // eslint-disable-line camelcase
      },
    }

    hiddenInput.value = JSON.stringify(uploadedFileData)

    uploadSuccessCallback(uploadURL)
  })
}
