import { Controller } from 'stimulus'
import { uppyInitWrapper } from '../../src/fileInputUpload'
import Cropper from 'cropperjs'

export default class extends Controller {
  static targets = [
    'image',
    'previewContainer',
    'fileInput',
    'hiddenInput',
    'rotateButton',
  ]

  connect() {
    this.initFileUploader()
    this.styleButtons()
  }

  initFileUploader() {
    const afterUploadSuccess = (uploadURL) => {
      this.buildCropBox(uploadURL)
      this.rotateButtonTarget.classList.remove('dpn')
    }

    uppyInitWrapper({
      input: this.fileInputTarget,
      hiddenInput: this.hiddenInputTarget,
      uploadSuccessCallback: afterUploadSuccess,
    })
  }

  buildCropBox(uploadURL) {
    const image = this.imageTarget
    const hiddenInput = this.hiddenInputTarget

    if (image.cropper) {
      image.cropper.replace(uploadURL)

      return
    }

    image.src = uploadURL

    new Cropper(image, {
      aspectRatio: 1,
      viewMode: 1,
      preview: this.previewContainerTarget,
      crop(event) {
        const inputValue = hiddenInput.value
        const fileData = JSON.parse(inputValue)

        fileData.metadata.crop = event.detail
        hiddenInput.value = JSON.stringify(fileData)
      },
    })
  }

  styleButtons() {
    const uploadButton = this.element.querySelector('.uppy-FileInput-btn')
    const wrapper = uploadButton.parentNode

    uploadButton.classList.add('btn', 'dpib')
    wrapper.classList.add('u-mt_large')
    wrapper.appendChild(this.rotateButtonTarget)
  }

  rotateImage(event) {
    event.preventDefault()
    this.imageTarget.cropper.rotate(90)
  }
}
