import { Controller } from 'stimulus'
import FroalaEditor from 'froala-editor'

// Locale
import 'froala-editor/js/languages/ja.js'

// Official Plugins
import 'froala-editor/js/plugins/align.min.js'
import 'froala-editor/js/plugins/char_counter.min.js'
import 'froala-editor/js/plugins/code_beautifier.min.js'
import 'froala-editor/js/plugins/code_view.min.js'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/js/plugins/draggable.min.js'
import 'froala-editor/js/plugins/emoticons.min.js'
import 'froala-editor/js/plugins/entities.min.js'
import 'froala-editor/js/plugins/file.min.js'
import 'froala-editor/js/plugins/font_family.min.js'
import 'froala-editor/js/plugins/font_size.min.js'
import 'froala-editor/js/plugins/fullscreen.min.js'
import 'froala-editor/js/plugins/help.min.js'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/js/plugins/image_manager.min.js'
import 'froala-editor/js/plugins/inline_class.min.js'
import 'froala-editor/js/plugins/inline_style.min.js'
import 'froala-editor/js/plugins/line_breaker.min.js'
import 'froala-editor/js/plugins/line_height.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/paragraph_format.min.js'
import 'froala-editor/js/plugins/paragraph_style.min.js'
import 'froala-editor/js/plugins/print.min.js'
import 'froala-editor/js/plugins/quick_insert.min.js'
import 'froala-editor/js/plugins/quote.min.js'
import 'froala-editor/js/plugins/special_characters.min.js'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/plugins/url.min.js'
import 'froala-editor/js/plugins/video.min.js'
import 'froala-editor/js/plugins/word_paste.min.js'
import 'froala-editor/js/third_party/embedly.min.js'

// custom plugins
import '../../src/admin/froala/custom_plugins/highlight_block'
import '../../src/admin/froala/custom_plugins/catalog'

// custom events
import tableInserted from '../../src/admin/froala/custom_events/table_inserted'

export default class extends Controller {
  connect() {
    this.imagesPath = this.element.dataset.imagesPath

    this.initEditor()
  }

  initEditor() {
    this.initCustomButtons()

    new FroalaEditor(this.element, {
      key: this.data.get('key'),
      attribution: false,
      height: 450,
      language: 'ja',
      toolbarSticky: false,
      pastePlain: true,
      wordPasteModal: false,
      tableResizer: false,
      tableDefaultWidth: null,
      paragraphStyles: this.customparagraphStyles(),
      tableCellStyles: this.customTableCellStyles(),
      inlineClasses: this.customInlineClasses(),
      toolbarButtons: this.customToolbarButtons(),
      events: this.customEvents(),
      enabledPlugins: this.enabledPlugins(),
      imageUploadURL: this.imagesPath,
      imageManagerLoadURL: this.imagesPath,

      // NOTE: Disable functionality of delete button in image manager
      imageManagerDeleteURL: null,
      imageDefaultWidth: 0,

      // NOTE: Disable adding extra classes "fr-fic fr-dib fr-draggable" to images
      imageMove: false,
      imageDefaultAlign: null,
      imageDefaultDisplay: null,

      htmlAllowedAttrs: [
        'accept',
        'accept-charset',
        'accesskey',
        'action',
        'align',
        'allowfullscreen',
        'allowtransparency',
        'alt',
        'aria-.*',
        'async',
        'autocomplete',
        'autofocus',
        'autoplay',
        'autosave',
        'background',
        'bgcolor',
        'border',
        'charset',
        'cellpadding',
        'cellspacing',
        'checked',
        'cite',
        'class',
        'color',
        'cols',
        'colspan',
        'content',
        'contextmenu',
        'controls',
        'coords',
        'data',
        'data-.*',
        'datetime',
        'default',
        'defer',
        'dir',
        'dirname',
        'disabled',
        'download',
        'draggable',
        'dropzone',
        'enctype',
        'for',
        'form',
        'formaction',
        'frameborder',
        'headers',
        'height',
        'hidden',
        'high',
        'href',
        'hreflang',
        'http-equiv',
        'icon',
        'id',
        'ismap',
        'itemprop',
        'keytype',
        'kind',
        'label',
        'lang',
        'language',
        'list',
        'loop',
        'low',
        'max',
        'maxlength',
        'media',
        'method',
        'min',
        'mozallowfullscreen',
        'multiple',
        'muted',
        'name',
        'novalidate',
        'open',
        'optimum',
        'pattern',
        'ping',
        'placeholder',
        'playsinline',
        'poster',
        'preload',
        'pubdate',
        'radiogroup',
        'readonly',
        'rel',
        'required',
        'reversed',
        'rows',
        'rowspan',
        'sandbox',
        'scope',
        'scoped',
        'scrolling',
        'seamless',
        'selected',
        'shape',
        'size',
        'sizes',
        'span',
        'src',
        'srcdoc',
        'srclang',
        'srcset',
        'start',
        'step',
        'summary',
        'spellcheck',
        'style',
        'tabindex',
        'target',
        'title',
        'type',
        'translate',
        'usemap',
        'value',
        'valign',
        'webkitallowfullscreen',
        'width',
        'wrap',
        'itemscope',
        'itemtype',
      ],
      htmlAllowedStyleProps: [
        '-webkit-tap-highlight-color',
        '-webkit-text-stroke-width',
        '-webkit-transform',
        'background',
        'background-attachment',
        'background-color',
        'border',
        'border-bottom',
        'border-bottom-color',
        'border-bottom-style',
        'border-collapse',
        'border-color',
        'border-image',
        'border-left',
        'border-left-color',
        'border-left-style',
        'border-radius',
        'border-right',
        'border-right-color',
        'border-right-style',
        'border-spacing',
        'border-style',
        'border-top',
        'border-top-color',
        'border-top-style',
        'border-width',
        'box-shadow',
        'box-sizing',
        'color',
        'cursor',
        'display',
        'flex-wrap',
        'float',
        'font-family',
        'font-size',
        'font-style',
        'font-variant-caps',
        'font-variant-ligatures',
        'font-weight',
        'height',
        'left',
        'letter-spacing',
        'line-height',
        'list-style-type',
        'margin',
        'margin-bottom',
        'margin-left',
        'margin-right',
        'margin-top',
        'max-width',
        'min-width',
        'opacity',
        'orphans',
        'outline',
        'overflow',
        'overflow-x',
        'padding',
        'padding-bottom',
        'position',
        'text-align',
        'text-decoration',
        'text-decoration-color',
        'text-decoration-style',
        'text-indent',
        'text-shadow',
        'text-size-adjust',
        'text-transform',
        'top',
        'transform',
        'transition',
        'vertical-align',
        'white-space',
        'widows',
        'width',
        'word-spacing',
      ],
    })
  }

  enabledPlugins() {
    return [
      'align',
      'charCounter',
      'codeBeautifier',
      'codeView',
      'colors',
      'draggable',
      'emoticons',
      'entities',
      'file',
      'fontFamily',
      'fontSize',
      'fullscreen',
      'help',
      'image',
      'imageManager',
      'inlineClass',
      'inlineStyle',
      'lineBreaker',
      'lineHeight',
      'link',
      'lists',
      'paragraphFormat',
      'paragraphStyle',
      'print',
      'quickInsert',
      'quote',
      'specialCharacters',
      'table',
      'url',
      'video',
      'wordPaste',
    ]
  }

  customparagraphStyles() {
    return {
      'h2-ordered-list': 'ナンバリング H2',
      'h3-ordered-list': 'ナンバリング H3',
    }
  }

  customTableCellStyles() {
    return { 'sticky-cell': 'Sticky' }
  }

  customEvents() {
    return {
      'table.inserted': function (table) {
        tableInserted(table)
      },
    }
  }

  customInlineClasses() {
    return {
      marker_pk: 'マーカーピンク',
      marker_gr: 'マーカー緑色',
      marker_yl: 'マーカー黄色',
    }
  }

  customToolbarButtons() {
    const textButtons = [
      'bold',
      'italic',
      'underline',
      'fontSize',
      'textColor',
      'inlineClass',
      'clearFormatting',
      'strikeThrough',
      'lineHeight',
      'subscript',
      'superscript',
      'backgroundColor',
      'inlineStyle',
    ]

    const paragraphButtons = [
      'alignLeft',
      'alignCenter',
      'alignRight',
      'paragraphFormat',
      'paragraphStyle',
      'formatOL',
      'formatUL',
      'alignJustify',
      'outdent',
      'indent',
      'quote',
    ]

    const richButtons = [
      'insertLink',
      'embedly',
      'insertImage',
      'insertTable',
      'insertVideo',
      'emoticons',
      'specialCharacters',
      'insertFile',
      'insertHR',
    ]

    const miscButtons = [
      'highlight-block',
      'catalog',
      'html',
      'fullscreen',
      'undo',
      'redo',
      'print',
      'getPDF',
      'spellChecker',
      'selectAll',
      'help',
    ]

    return {
      moreText: {
        buttons: textButtons,
        align: 'left',
        buttonsVisible: 7,
      },

      moreParagraph: {
        buttons: paragraphButtons,
        align: 'left',
        buttonsVisible: 7,
      },

      moreRich: {
        buttons: richButtons,
        align: 'left',
        buttonsVisible: 4,
      },

      moreMisc: {
        buttons: miscButtons,
        align: 'right',
        buttonsVisible: 6,
      },
    }
  }

  initCustomButtons() {
    FroalaEditor.DefineIconTemplate(
      'customizeIconTemplate',
      '<i class="fa fa-[NAME] text-xl" aria-hidden="true"></i>'
    )

    FroalaEditor.ICON_DEFAULT_TEMPLATE = 'customizeIconTemplate'

    FroalaEditor.DefineIcon('inlineClass', { NAME: 'pencil' })
    FroalaEditor.DefineIcon('specialCharacters', { NAME: 'usd' })
    FroalaEditor.DefineIcon('highlight-block', { NAME: 'lightbulb-o' })
    FroalaEditor.DefineIcon('catalog', { NAME: 'list-alt' })
  }
}
